<template>
  <!-- <transition name="fade" mode="out-in"> -->

  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="game-body">
          <ErrorDialog
            v-if="error"
            :error="error"
            :message="errorMessage"
            @exitError="extiError"
          />
          <div class="d-flex flex-column pa-5">
            <div class="d-flex justify-space-between">
              <div class="text-h6">
                {{ $t("string.chapter") }} : {{ question.chapter_name }}
              </div>
            </div>
            <div>{{ $t("string.question") }} : {{ question.name }}</div>
            <div class="d-flex justify-center">
              <AbstractAvatar
                :avatar="auth.Player.avatar"
                :height="150"
                :width="150"
                class=""
              ></AbstractAvatar>
            </div>

            <v-btn
              v-if="!QuestionApi.isLoading"
              class="text-h6"
              block
              large
              @click="play()"
              id="StartGame"
            >
              {{ $t("action.start") }}
            </v-btn>
            <v-btn v-if="settings.debug" class="text-h6 mt-3" block large text @click="skip()">
              Skip
            </v-btn>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
export default {
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
    ErrorDialog: () =>
      import(
        /* webpackChunkName: "component-error-dialog" */ "@/components/ErrorDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    boss: (state) => state.boss.data,
    event: (state) => state.event.data,
    miniGame: (state) => state.miniGame.data,
    tutorial: (state) => state.tutorial.data,
    settings: (state) => state.settings.data,
  }),
  data: () => ({
    schoolYear: null,
    classroomMonth: 3,
    intraction: null,
    error: false,
    errorMessage: null,
    boss_data: null,
    questionKey: null,
    chapterKey: null,
    ladder: 4,
    QuestionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    gameApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    tutorialApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    difficulty: 1,
    level: {},
    question: {},
    event_data: {},
    students: [],
    student_temp: [],
  }),
  created() {
    this.QuestionApi.callbackReset = () => {
      this.QuestionApi.isLoading = true;
      this.QuestionApi.isError = false;
    };

    this.QuestionApi.callbackError = (e) => {
      this.QuestionApi.isLoading = false;
      this.QuestionApi.isError = true;
      this.QuestionApi.error = e;
    };
    this.QuestionApi.callbackSuccess = (resp) => {
      this.question = resp.find((element) => element.key == this.questionKey);
      this.QuestionApi.isLoading = false;
    };
    this.QuestionApi.method = "GET";

    this.QuestionApi.params = { lang: this.$_getLocale() };
  },
  mounted() {
    this.questionKey = this.miniGame.q;
    this.chapterKey = this.miniGame.c;
    this.QuestionApi.url =
      this.$api.servers.question +
      "/v2/chapters/" +
      this.chapterKey +
      "/questions?lang=" +
      this.$_getLocale();
    this.$api.fetch(this.QuestionApi);
    if (this.auth.Classroom) {
      this.schoolYear =this.auth.Classroom.schoolYear;
        this.classroomMonth = this.auth.Classroom.month
    } else if (this.auth.Player.userType == "adult") {
      this.schoolYear = 1;
    } else {
      this.schoolYear =this.auth.User.schoolYear;
        this.classroomMonth = this.auth.User.month
    }
   
   
  },
  methods: {
    extiError() {
      this.error = false;
    },
    async getAvatars() {
      for (let i = 0; i < this.student_temp.length; i++) {
        await this.gameApiCall(this.student_temp[i]);
      }
    },
    async gameApiCall(element) {
      this.gameApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/player/" +
        element.mochiId;

      this.gameApi.callbackReset = () => {
        this.gameApi.isLoading = true;
        this.gameApi.isError = false;
      };

      this.gameApi.callbackError = (e) => {
        this.gameApi.isLoading = false;
        this.gameApi.isError = true;
        this.gameApi.error = e;
      };
      this.gameApi.callbackSuccess = (resp) => {
        this.gameApi.isLoading = false;
        element.name = resp.name;
        element.avatar = resp.avatar;
        this.students.push(element);
      };
      this.gameApi.method = "GET";
      await this.$api.fetch(this.gameApi);
    },
    play() {
      // var event_data = true
      // this.$store.commit("updateEvent", event_data);
      let tempSettings = this.$_.cloneDeep(this.settings);
      tempSettings.cache = true;
      this.$store.commit("updateSettings", tempSettings);
      this.$router.push({
        name: "PageExploreDailyGamePlay",
      });
    },
    skip() {
   let  score = Math.floor(Math.random() * 4) *200;
      this.$store.commit("updateMiniGame", {score:score});
      this.$router.push({
        name: "PageExploreDailyGameScore",
      });
    },
    exit() {
      this.$router.push({
        name: "PageExploreDailyGame",
      });
    },
  },
};
</script>

<style>
.game-body {
  max-width: 480px;
  height: calc(100% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(49, 214, 49, 0.3);
  position: relative;
}

.dif {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>